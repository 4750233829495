import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col, Visible } from "react-grid-system"
import logo from "../images/logo-multi-csr.svg"
import { ItemsList } from "./utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import {
  faInstagram,
  faFacebookF,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link } from "gatsby"

const Header = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col>
          <Nav>
            <Link to="/">
              <img src={logo} alt="MULTI Logo" />
            </Link>
            <Visible md lg xl xxl>
              <CustomItemList>
                <li>
                  <OutboundLink
                    href="http://www.youtube.com/user/Multiconsultbergamo"
                    target="blank"
                    rel="noopener nofollow"
                  >
                    <FontAwesomeIcon icon={faYoutube} size="2x" />
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href="https://www.instagram.com/multi_marketing_consulting/"
                    target="blank"
                    rel="noopener nofollow"
                  >
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href="https://www.facebook.com/Multimarketingconsulting/"
                    target="blank"
                    rel="noopener nofollow"
                  >
                    <FontAwesomeIcon icon={faFacebookF} size="2x" />
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href="http://www.linkedin.com/company/multi-consult-s-r-l-"
                    target="blank"
                    rel="noopener nofollow"
                  >
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink href="https://www.multi-consult.com">
                    MULTI-CONSULT.IT →
                  </OutboundLink>
                </li>
              </CustomItemList>
            </Visible>
            <Visible xs sm>
              <CustomItemList>
                <li>
                  <OutboundLink href="https://www.multi-consult.com">
                    <FontAwesomeIcon icon={faGlobe} size="2x" />
                  </OutboundLink>
                </li>
              </CustomItemList>
            </Visible>
          </Nav>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Wrapper = styled.div`
  background: ${DesignTokens.colors.white};
  border-bottom: 1px solid ${DesignTokens.colors.primary[500]};
`

const Nav = styled.nav`
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  img {
    width: 160px;
    @media screen and (max-width: 767px) {
      width: 120px;
    }
  }
`

const CustomItemList = styled(ItemsList)`
  display: flex;
  flex-direction: row;
  align-items: center;
  li {
    margin-left: -1px;
    a {
      position: relative;
      font-weight: 900;
      text-decoration: none;
      font-size: ${DesignTokens.fontSizes[3]};
      color: ${DesignTokens.colors.primary[500]};
      border: 1px solid ${DesignTokens.colors.primary[500]};
      display: block;
      padding: ${DesignTokens.spacing[2]};
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: ${DesignTokens.colors.secondary[500]};
        border: 1px solid ${DesignTokens.colors.secondary[500]};
        z-index: 10;
      }
      @media screen and (min-width: 769px) {
        font-size: ${DesignTokens.fontSizes[4]};
      }
    }
    &:last-child {
      a {
        width: auto;
        padding: 0 ${DesignTokens.spacing[5]};
      }
    }
  }
`

export default Header

import { Link } from "gatsby"
import styled from "styled-components"
import check from "../images/check.svg"
import DesignTokens from "./designTokens.js"

const BtnLink = styled(Link)`
  color: ${props =>
    props.correct
      ? DesignTokens.colors.primary[500]
      : DesignTokens.colors.tertiary[500]};
  background: ${DesignTokens.colors.primary[500]};
  display: inline-block;
  padding: ${DesignTokens.spacing[5]} ${DesignTokens.spacing[6]};
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  border-radius: ${DesignTokens.borderRadius[0]};
  font-weight: 700;
  box-shadow: ${DesignTokens.shadows.raised};
  transition: all 0.25s ease;
  &:hover,
  &:focus {
    box-shadow: ${DesignTokens.shadows.floating};
    background: ${DesignTokens.colors.primary[400]};
  }
  &:active {
    box-shadow: none;
  }
`

const BtnLinkSecondary = styled(BtnLink)`
  color: ${DesignTokens.colors.primary[500]};
  background: ${DesignTokens.colors.tertiary[500]};
  border: 1px solid ${DesignTokens.colors.primary[500]};
  &:hover,
  &:focus {
    background: ${DesignTokens.colors.tertiary[500]};
  }
  &:active {
    background: ${DesignTokens.colors.tertiary[500]};
  }
`
const BtnLinkTertiary = styled(BtnLink)`
  color: ${DesignTokens.colors.tertiary[500]};
  background: transparent;
  position: relative;
  border: 1px solid ${DesignTokens.colors.tertiary[500]};
  &:hover,
  &:focus {
    color: ${DesignTokens.colors.primary[500]};
    background: ${DesignTokens.colors.tertiary[500]};
  }
  &:active {
    background: ${DesignTokens.colors.tertiary[500]};
  }
  &:disabled {
    color: ${props =>
      props.correct
        ? DesignTokens.colors.primary[500]
        : DesignTokens.colors.tertiary[500]};
    background: ${props =>
      props.correct ? DesignTokens.colors.tertiary[500] : "transparent"};
    opacity: ${props => (props.correct ? 1 : 0.5)};
    &:hover,
    &:focus {
      box-shadow: none;
    }
    &:after {
      content: url(${check});
      position: absolute;
      bottom: -1.5rem;
      right: -1.25rem;
      display: ${props => (props.correct ? "block" : "none")};
      @media screen and (max-width: 1023px) {
        bottom: -1.75rem;
        right: 1rem;
      }
    }
  }
`

const BtnGroup = styled.div`
  display: flex;
  flex-direction: row;
  * {
    margin: 0 ${DesignTokens.spacing[4]};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    * {
      width: 100%;
      margin: ${DesignTokens.spacing[2]} 0;
      &:first-child {
        margin-top: 0;
        z-index: 1;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export { BtnLink, BtnLinkSecondary, BtnLinkTertiary, BtnGroup }
